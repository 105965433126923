import type { InternalConfig, Options } from "../_model";
import { KEYS, warn } from "../const";

export function initToken(options: Options, internal_config: InternalConfig) {
  try {
    if (!internal_config.access_denied.localstorageDenied) {
      if (options.auth?.manulTokenExpiration) {
        const item = localStorage.getItem(KEYS.LOCALSTORAGE_TOKEN_MANUAL_EXPIRE);
        if (item && item !== "undefined") {
          const date_string = JSON.parse(item);
          const date = new Date(date_string);
          internal_config.manualTokenExpire = date;
        }
      }
      //
      if (options?.auth?.mode === "localstorage" && !internal_config.access_denied.localstorageDenied) {
        const item = localStorage?.getItem?.(KEYS.LOCALSTORAGE_TOKEN);
        if (item !== undefined && item !== null && item !== "undefined") {
          return JSON.parse(item);
        }
      }
    }
  } catch (e) {
    warn(
      "an error occured while trying to init token. probably because user has blocked cookies and localstorage access \n",
      e
    );
  }
  // internal_config.initizlized = true;
  return undefined;
}
